<template>
  <div class="top-register-form">
    <p class="border-box load-border" v-if="$route.name === 'prices'"  style="margin-left: 20%;width: 60%;padding: 10px 0px;margin-bottom: 20px;font-size: 14px;">
      {{ __(general).form.campaign }}
    </p>
    <form action="https://admin.kobisi.com/e-ticaret-ucretsiz-deneme-surumu" id="register-form" target="_blank">
      <div class="f-row">
       <!-- <input type="hidden" name="single" value="true">
        <input type="email" name="user-email" :placeholder="__(general).form.placeholder.email" />
        <button class="primary">{{ __(general).form.button }}</button>-->
        <router-link to="/lp-kobisi-eticaret-paketleri">
           <button type="button" class="primary">{{ __(general).form.button }}</button>
        </router-link>
      </div>
    </form>
    <!-- <form @submit.prevent="submitForm">
      <div class="f-row">
        <input
          type="text"
          name="user-name"
          :placeholder="__(general).form.placeholder"
          v-model="userName"
        />
        <input
          type="text"
          name="user-email"
          :placeholder="__(general).form.placeholder"
          v-model="userEmail"
        />
        <input
          type="text"
          name="user-tel"
          :placeholder="__(general).form.placeholder"
          v-model="userTel"
        />
        <input
          type="text"
          name="store-name"
          :placeholder="__(general).form.placeholder"
          v-model="storeName"
        />
        <button  class="primary">{{ __(general).form.button }}</button>
      </div>
      <div>
      <h3>Data retrieved from server:</h3>
      <p v-if="success"> {{ success }}</p>
      <pre>{{ response }}</pre>
    </div>
    </form> -->
    <p v-if="$route.name != 'prices'">
      {{ __(general).form.information }}
    </p>
  </div>
</template>

<script>
// import axios from "axios";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "trialFormComponent",
  data() {
    return {
      userName: '',
      userEmail: '',
      userTel: '',
      storeName: '',
      response: '',
      success: '',
      activeClass: 'active'
    };
  },
  methods: {
    // submitForm() {
    //   axios.post('https://admin.kobisi.com/e-ticaret-ucretsiz-deneme-surumu', {
    //     userName: this.userName,
    //     userEmail: this.userEmail,
    //     userTel: this.userTel,
    //     storeName: this.storeName
    //   }).then(response => {
    //     // console.log(response);
    //     // this.response = response.data
    //     this.success = 'Data saved successfully';
    //     this.response = JSON.stringify(response, null, 2)
    //     console.log(this.response)
    //   }).catch(error => {
    //     this.response = 'Error: ' + error.response.status
    //   })
    //   this.userName = '';
    //   this.userEmail = '';
    //   this.userTel = '';
    //   this.storeName = '';
    // }
  },
  props: {
    lang: String,
    general: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./trialForm.scss" lang="scss" scoped>

</style>
