<template>
  <main>
    <CoolLightBox
      :items="__(title).packagesAllFeatures"
      :index="index"
      @close="index = null"
    >
    </CoolLightBox>
    <section class="page-inner-header">
      <div class="container">
        <div class="page-title">
          <h1>{{ __(title).title }}</h1>
          <h2>{{ __(title).text }}</h2>
          <trialFormComponent :lang="lang" general="general" />
        </div>
      </div>
    </section>
    <section class="packages-cards">
      <div class="container">
        <div class="tab-navigation">
            <ul>
              <li v-on:click="packageType('annual')" :class="activePackage=='annual'?'active':''">{{ __(general).pricesPage.packeageTypeAnnualTab }}</li>
              <li v-on:click="packageType('monthly')" :class="activePackage=='monthly'?'active':''">{{ __(general).pricesPage.packeageTypeMonthlyTab }}</li>
            </ul>
        </div>
        <div class="swiper-tab">
          <div class="swiper-pagination"></div>
        </div>
        <div class="packages swiper" ref="swiper">
          <div class="swiper-wrapper">
            <div
              class="packages-item swiper-slide text-center"
              :class="packageItem.slug"
              v-for="packageItem in __(title).packages"
              :key="packageItem.id"
            >
            <span class="slogan" v-if="packageItem.slogan && packageItem.slogan != null">{{ packageItem.slogan }}</span>
              
            <div class="inner-wrap" :style="packageItem.name === 'Personal'?'padding-top: 10px':''">
            <span class="info-badge" v-if="packageItem.name === 'Personal'">Yeni Başlayanlar İçin</span>
                <div
                class="package-name"
                v-if="packageItem.name && packageItem.name != null"
                >
                <span class="discount" v-if="(activePackage=='annual')&&(packageItem.discount && packageItem.discount != null)">{{ packageItem.discount }}</span>
                {{ packageItem.name }}
              </div>
                <div class="package-prices" >
                  <div
                      :class="
                        packageItem.price && packageItem.price != null
                          ? 'normal-price'
                          : 'getOffer'
                      " >
                      {{
                        packageItem.price && packageItem.price != null
                          ? (activePackage=='annual'?packageItem.price.annual:packageItem.price.monthly)
                          : __(general).pricesPage.getOffer
                      }}

                    <div
                      v-if="packageItem.price && packageItem.price != null && packageItem.type == 'monthly'"
                      class="packae-type" style="font-size: 0.4em;"
                    >
                      / {{ __(general).pricesPage.packeageTypeMonthly }}
                    </div>
                  </div>
                 <div>
                   <div
                    style="font-size: 1.1em;"
                    class="discounted-price"
                    v-if="
                      packageItem.discounted_price &&
                      packageItem.discounted_price != null && activePackage=='annual'
                    "
                  >
                    {{ activePackage=='annual'?packageItem.discounted_price.annual:packageItem.discounted_price.monthly }}
                  </div> 

                  <div
                    class="discounted-price"
                    v-if="
                      packageItem.discounted_price &&
                      packageItem.discounted_price != null && activePackage=='monthly'
                    "
                  >
                  
                  </div>

                   <div
                    style="font-size: 1.3em;line-height: 1.1;opacity: 0.7;"
                    class="discounted-prices"
                    v-if="
                      packageItem.price &&
                      packageItem.price != null &&
                      activePackage=='annual'
                    "
                  >
                    {{ activePackage=='annual'?packageItem.price.realPrice:packageItem.price.realPrice }}
                  </div>

                 </div>
                  <div
                    class="package-type-free"
                    v-if="packageItem.free == true"
                  >
                    {{
                      packageItem.free == true
                        ? __(general).pricesPage.free
                        : ""
                    }}
                  </div>
                </div>
                <div class="sepesific-feature-list" >
                  <div
                    class="sepesific-feature-list-item"
                    v-for="specificFeaturesItem in packageItem.specificFeatures"
                    :key="specificFeaturesItem"
                  >
                    <svg class="shape">
                      <use
                        xlink:href="../../assets/images/icon/icon-sprite.svg#checkmark"
                      />
                    </svg>
                    <span>{{ specificFeaturesItem }}
                       <span class="free-badge" v-if="specificFeaturesItem === 'Trendyol Entegrasyonu'">Ücretsiz</span>
                    </span>
                  </div>
                </div>
                <template v-if="packageItem.slug=='prime'">
                  <a href="/lp-kobisi-eticaret-paketleri" class="bttn primary radiused outline">{{
                  __(general).pricesPage.getOffer
                }}</a>
                </template>

                <template v-else>
                <a href="/lp-kobisi-eticaret-paketleri" class="bttn primary radiused outline">{{
                  __(general).pricesPage.packageRegister
                }}</a>
                </template>
                <button class="mobile-fetures-opener" v-on:click="showFeatures">
                  {{
                    showMobileFeature === false
                      ? __(general).pricesPage.comparePackeageFeatures
                      : __(general).pricesPage.hidePackeageFeatures
                  }}
                </button>

                <div class="package-mobile-features" v-if="showMobileFeature">
                  <template
                    v-for="(
                      packagesFeature, packagesFeatureIndex
                    ) in packageItem.features"
                  >
                    <template
                      v-for="(allFeatureItem, allFeatureItemIndex) in __(title)
                        .packagesAllFeatures"
                    >
                      <div
                        class="package-mobile-features-item"
                        v-if="allFeatureItem.id === packagesFeature.id"
                        :key="(allFeatureItemIndex, packagesFeatureIndex)"
                      >
                        <div class="feature-name">
                          {{ allFeatureItem.name }}
                        </div>
                        <div class="feature-value">
                          {{
                            packagesFeature.id === allFeatureItem.id &&
                            packagesFeature.is === false
                              ? "-"
                              : ""
                          }}

                          <template
                            v-if="
                              packagesFeature.id === allFeatureItem.id &&
                              packagesFeature.is === true
                            "
                          >
                            <svg class="shape checkmark">
                              <use
                                xlink:href="../../assets/images/icon/icon-sprite.svg#checkmark"
                              />
                            </svg>
                          </template>
                          <template
                            v-if="
                              packagesFeature.id === allFeatureItem.id &&
                              packagesFeature.is !== false &&
                              packagesFeature.is !== true
                            "
                          >
                            {{ packagesFeature.is }}
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-button-next">
            <svg class="shape">
              <use xlink:href="../../assets/images/icon/icon-sprite.svg#next" />
            </svg>
          </div>
          <div class="swiper-button-prev">
            <svg class="shape">
              <use xlink:href="../../assets/images/icon/icon-sprite.svg#back" />
            </svg>
          </div> -->
        </div>
      </div>
    </section>
    <horizontalInfographicComponent
      :lang="lang"
      :title="'pages.prices.why'"
    ></horizontalInfographicComponent>
    <div class="features-all">
      <button
        class="primary radiused outline"
        :class="featuresActive === true ? 'show' : ''"
        v-on:click="openFeature"
      >
        {{
          featuresActive === false
            ? __(general).pricesPage.comparePackeageFeatures
            : __(general).pricesPage.hidePackeageFeatures
        }}
        <svg class="shape">
          <use
            xlink:href="../../assets/images/icon/icon-sprite.svg#down-arrow"
          />
        </svg>
      </button>
    </div>
    <section
      class="all-package"
      :class="featuresActive ? 'features-active' : ''"
    >
      <div class="container">
        <table>
          <thead>
            <tr>
              <th>
                <div class="hide-feature" v-on:click="hideSameFeatures">
                  <!-- <div
                    class="switch-button"
                    :class="hideSamefeaturesActive ? 'active' : 'passive'"
                  ></div> -->
                  <p>

                    {{__(general).pricesPage.tableInfo}}
                  </p>
                  <span>{{
                    hideSamefeaturesActive
                      ? __(general).pricesPage.tableShow
                      : __(general).pricesPage.tableHide
                  }}</span>
                </div>
              </th>
              <th
                v-for="packageItem in __(title).packages"
                :key="packageItem.id"
              >
                <div class="price-table-column-head" :class="packageItem.slug">
                  <div class="package-name">
                    {{ packageItem.name }}
                  </div>

                  <div class="package-prices">
                    <div
                      class="discounted-price"
                      v-if="
                        packageItem.discounted_price &&
                        packageItem.discounted_price != null && activePackage=='annual'
                      "
                    >
                    {{ activePackage=='annual'?packageItem.discounted_price.annual:packageItem.discounted_price.monthly }}
                    </div>
                    <div
                      :class="
                        packageItem.price && packageItem.price != null
                          ? 'normal-price'
                          : 'getOffer'
                      "
                    >
                    {{
                        packageItem.price && packageItem.price != null
                          ? (activePackage=='annual'?packageItem.price.annual:packageItem.price.monthly)
                          : __(general).pricesPage.getOffer
                      }}
                      <div
                        v-if="
                          packageItem.free != true && packageItem.price != null
                        "
                        class="packae-type"
                      >
                        / {{ __(general).pricesPage.packeageTypeMonthly }}
                      </div>
                    </div>
                    <div
                      class="package-type-free"
                      v-if="packageItem.free == true"
                    >
                      {{
                        packageItem.free == true
                          ? __(general).pricesPage.free
                          : ""
                      }}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(allFeatureItem, allFeatureItemIndex) in __(title)
                .packagesAllFeatures"
              :key="allFeatureItemIndex"
            >
              <td>
                <span
                  >{{ allFeatureItem.name }}
                  <template v-if="allFeatureItem.src">
                    <div
                      class="allFeatureItem-video row-tool"
                      @click="index = allFeatureItemIndex"
                    >
                      <svg class="shape">
                        <use
                          xlink:href="../../assets/images/icon/icon-sprite.svg#videoLink"
                        />
                      </svg>
                    </div>
                  </template>
                  <template v-if="allFeatureItem.description">
                    <div 
                      class="allFeatureItem-info row-tool"  
                      v-b-popover.hover.html="allFeatureItem.description"
                    >
                      <svg class="shape">
                        <use
                          xlink:href="../../assets/images/icon/icon-sprite.svg#info"
                        />
                      </svg>
                    </div>
                  </template>
                </span>
              </td>
              <td
                v-for="packageItem in __(title).packages"
                :key="packageItem.id"
              >
                <template v-for="featuresItem in packageItem.features">
                  <template v-if="featuresItem.id === allFeatureItem.id">
                    <!-- {{
                      featuresItem.id === allFeatureItem.id
                        ? (featuresItem.is === true
                            ? "var"
                            : "" || featuresItem.is === false
                            ? "yok"
                            : "") ||
                          (featuresItem.is != false || featuresItem.is != true
                            ? featuresItem.is
                            : "")
                        : ""
                    }} -->

                    <!-- {{featuresItem.id === allFeatureItem.id?(featuresItem.is === true?"var":""):""}} -->
                    {{
                      featuresItem.id === allFeatureItem.id &&
                      featuresItem.is === false
                        ? "-"
                        : ""
                    }}
                    <!-- {{featuresItem.id === allFeatureItem.id?(featuresItem.is !== false && featuresItem.is !== true? featuresItem.is: ""):""}} -->

                    <template
                      v-if="
                        featuresItem.id === allFeatureItem.id &&
                        featuresItem.is === true
                      "
                    >
                      <svg class="shape checkmark" :key="featuresItem.id">
                        <use
                          xlink:href="../../assets/images/icon/icon-sprite.svg#checkmark"
                        />
                      </svg>
                    </template>
                    <template
                      v-if="
                        featuresItem.id === allFeatureItem.id &&
                        featuresItem.is !== false &&
                        featuresItem.is !== true
                      "
                    >
                      {{ featuresItem.is }}
                    </template>
                  </template>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </main>
</template>

  <script>
// Import Swiper Vue.js components
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import horizontalInfographicComponent from "@/components/horizontalInfographic/horizontalInfographic.vue";
import trialFormComponent from "@/components/trialForm/trialForm.vue";
import CoolLightBox from "vue-cool-lightbox";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  mixins: [tr, en],
  name: "pricesTableComponent",
  data() {
    return {
      index: null,
      featuresActive: false,
      hideSamefeaturesActive: false,
      showMobileFeature: false,
      activePackage:'annual'
    };
  },
  props: {
    lang: String,
    title: String,
    general: String,
  },
  components: {
    horizontalInfographicComponent,
    trialFormComponent,
    CoolLightBox,
  },
  mounted() {
    var packageNames = this[this.lang].pages.prices.packages;
    this.activePackage="annual";
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],

      // Optional parameters
      slidesPerView: 2,
      spaceBetween: 30,
      autoHeight: true,
      loopFillGroupWithBlank: false,
      loop: false,
      centeredSlides: true,
      centeredSlidesBounds: false,
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return (
            '<span class="' +
            className +
            '">' +
            packageNames[index].name+
            "</span>"
          );
        },
      },
      // Navigation arrows
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      breakpoints: {
        0: {
          slidesPerView: "auto",
          centeredSlides: true,
          spaceBetween: 20,
          initialSlide: 2,
        },
        991: {
          slidesPerView: 4,
          slidesPerGroup: 1,
          spaceBetween: 20,
          centeredSlides: true,
          initialSlide: 2,
        },
        1280: {
          slidesPerView: 5,
          slidesPerGroup: 1,
          spaceBetween: 20,
          centeredSlides: false,
        },
      },

      // And if we need scrollbar
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
  },
  methods: {
    packageType: function (type) {
      this.activePackage=type;
    },
    openFeature: function () {
      this.featuresActive = !this.featuresActive;
      console.log(this.featuresActive);
      //  var contained = false;
    },
    hideSameFeatures: function () {
      var fullrow = document.querySelectorAll("tr");
      this.hideSamefeaturesActive = !this.hideSamefeaturesActive;
      if (this.hideSamefeaturesActive === true) {
        // console.log(fullrow.length)
        for (var i = 0; i < fullrow.length; i++) {
          if (fullrow[i].querySelectorAll(".checkmark").length == 5) {
            fullrow[i].style.display = "none";
          }
        }
      } else {
        for (var j = 0; j < fullrow.length; j++) {
          // fullrow[fullrow[i]].style= "display","none";

          fullrow[j].style.display = "table-row";
        }
      }
    },
    showFeatures: function () {
      this.showMobileFeature = !this.showMobileFeature;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./pricesTable.scss" lang="scss" scoped>
</style>
