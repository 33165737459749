<template  v-if="lang && __(title)">
  <section class="faq-section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="section-title">
            <h3>
              {{ __(title).title }}
            </h3>
              <p>{{ __(title).text }}</p>
          <a class="bttn radiused primary" href="https://wa.me/905353453912" target="_blank">{{__(general).pricesPage.callUs}}</a>
          </div>
        </div>
        <div class="col-12 col-md-7 offset-md-1">
          <div class="faq-wrapper">
            <div
              class="faq-item"
              v-for="(faqItem, index) in __(title).list"
              :key="index"
              v-on:click="faqOpener(index)"
              v-bind:class="{ 'faq-acive': currentFaq === index }"
            >
              <div class="faq-title">
                <h4>{{ faqItem.title }}</h4>
                <svg class="shape">
                  <use
                    xlink:href="../../assets/images/icon/icon-sprite.svg#down-arrow"
                  />
                </svg>
              </div>
              <transition
                mode="out-in"
                name="faq-active"
                v-on:before-enter="beforeEnterFaq"
                v-on:enter="enterFaq"
              >
                <div class="faq-description" v-show="currentFaq === index">
                  <div class="inner-wrapper" v-html="faqItem.description"></div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

    <script>
// Import Swiper Vue.js components
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  data() {
    return {
      currentFaq: -10,
    };
  },
  mixins: [tr, en],
  name: "faqSection",
  props: {
    lang: String,
    title: String,
    general: String,
  },
  methods: {
    faqOpener: function (newFaq) {
      if (newFaq === this.currentFaq) {
        this.currentFaq = -10;
      } else {
        this.currentFaq = newFaq;
      }
    },
    beforeEnterFaq: function (_t) {
      _t.style.display = "block";
      _t.style.maxHeight = null;
      _t.myHeight = _t.offsetHeight;
      _t.style.maxHeight = 0;
      _t.style.display = null;
    },
    enterFaq: function (_t) {
      _t.style.maxHeight = _t.myHeight + "px";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./faqSection.scss" lang="scss" scoped>
</style>
