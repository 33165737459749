<template>
  <main>
    <section class="themes">
      <div class="paralax-item shape-dotes">
        <div class="paralax-item-inner bottom speed-20">
          <svg class="shape">
            <use xlink:href="../../assets/images/icon/shape-sprite.svg#dotes" />
          </svg>
        </div>
      </div>

      <div class="section-wrap">
        <div class="section-title cntr">
          <h1>{{__(title).title}}</h1>
          <h2>
            {{__(title).description}}
          </h2>
        </div>

        <div class="button-container">
          <div class="container">
            <div class="buttons">
              <div class="swiper-button-prev">
                <svg class="shape">
                  <use
                    xlink:href="../../assets/images/icon/icon-sprite.svg#back"
                  />
                </svg>
              </div>
              <div><span id="numberSlides"></span></div>
              <div class="swiper-button-next">
                <svg class="shape">
                  <use
                    xlink:href="../../assets/images/icon/icon-sprite.svg#next"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="themes-list slider-list swiper" ref="swiperSlider">
          <div class="slider-wrap swiper-wrapper">
            <div
              class="themes-list-item swiper-slide"
              v-for="listitem in __(title).list"
              :key="listitem.id"
            >
              <div class="container">
                <div
                  class="inner-wrapper"
                  :class="[__(title).animated == true ? 'animated' : '']"
                >
                  <div class="theme-info">
                    <div class="item-wrap">
                      <h2 class="theme-name">{{ listitem.name }}</h2>
                      <div class="theme-price">{{ listitem.price }}</div>
                      <ul class="theme-features">
                        <li v-for="feature in listitem.features" :key="feature">
                          {{ feature }}
                        </li>
                      </ul>
                    </div>
                    <div class="item-action">
                      <div
                        class="bttn dark outline radiused small"
                        @click="goDemo(listitem.href)"
                      >
                        {{ __(general).themes.viewTheme }}
                      </div>
                      <!-- <a
                    :href="listitem.href"
                    class="bttn light outline radiused small"
                    target="_blank"
                  >
                    {{ __(general).themes.viewTheme }}
                  </a> -->
                    </div>
                  </div>
                  <div class="theme-preview">
                    <div class="desktop">
                      <div class="buttons">
                        <span class=""></span>
                        <span class=""></span>
                        <span class=""></span>
                        <div class="textbox">www.kobisi.com</div>
                      </div>
                      <img :src="listitem.desktop" alt="" />
                    </div>
                    <div class="mobile">
                      <img :src="listitem.mobile" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="themes-customer">
      <div class="paralax-item shape-circle">
        <div class="paralax-item-inner right bottom speed-3">
          <svg class="shape">
            <use
              xlink:href="../../assets/images/icon/shape-sprite.svg#circle"
            />
          </svg>
        </div>
      </div>
      <div class="paralax-item shape-triangle">
        <div class="paralax-item-inner bottom spin speed-3">
          <svg class="shape">
            <use
              xlink:href="../../assets/images/icon/shape-sprite.svg#triangle"
            />
          </svg>
        </div>
      </div>
      <div class="section-wrap">
        <div class="section-title cntr">
          <h2>{{ __(title).customerList.title }}</h2>
          <p>{{ __(title).customerList.description }}</p>
        </div>
        <div class="container">
          <div class="themes-customer-list row row-cols-1 row-cols-md-2">
            <div
              class="themes-customer-list-item text-center animated"
              v-for="listitem in __(title).customerList.list"
              :key="listitem.id"
              @click="goDemo(listitem.link)"
            >
              <div
                class="inner-wrapper"
                :class="[__(title).animated == true ? 'animated' : '']"
              >
                <div class="theme-preview">
                  <div class="preview">
                    <img :src="listitem.image" alt="" />
                  </div>
                </div>
                <div class="theme-info">
                  <div class="item-wrap">
                    <h3 class="theme-user">{{ listitem.title }}</h3>
                    <h4 class="theme-name">{{ listitem.theme }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="demo-section" v-if="demoSection">
      <div class="demo-section__header">
        <div class="container">
          <div class="demo-section__header__frame-options">
            <div
              class="option demo-section__header__frame-close"
              @click="closeDemo"
            >
              <svg class="shape">
                <use
                  xlink:href="../../assets/images/icon/icon-sprite.svg#back"
                />
              </svg>
              <span>
                {{ __(general).themes.closePreview }}
              </span>
            </div>
            <div
              class="option demo-section__header__frame-desktop"
              :class="mode == 'mode-desktop' ? 'active' : ''"
              @click="selectMode('mode-desktop')"
            >
              <svg class="shape">
                <use
                  xlink:href="../../assets/images/icon/icon-sprite.svg#desktop"
                />
              </svg>
            </div>
            <div
              class="option demo-section__header__frame-mobile"
              :class="mode == 'mode-mobile' ? 'active' : ''"
              @click="selectMode('mode-mobile')"
            >
              <svg class="shape">
                <use
                  xlink:href="../../assets/images/icon/icon-sprite.svg#mobile"
                />
              </svg>
            </div>
          </div>
          <div class="demo-section__header__frame-actions">
            <router-link class="bttn primary small radiused" to="/lp-kobisi-eticaret-paketleri">
              {{
                __(general).form.button
              }}  
              
            </router-link>
          </div>
        </div>
      </div>
      <div class="demo-section__frame" :class="mode">
        <iframe
          anonymous
          id="demo-frame"
          :src="demoUrl"
          frameborder="0"
        ></iframe>
      </div>
    </section>
  </main>
</template>
<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
export default {
  mixins: [tr, en],
  name: "themesDetailComponent",
  data() {
    return {
      slides: [],
      currentSlide: null,
      demoSection: false,
      demoUrl: "",
      mode: "mode-desktop",
    };
  },

  props: {
    lang: String,
    title: String,
    general: String,
  },

  mounted() {
    new Swiper(this.$refs.swiperSlider, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],

      autoHeight: false,
      loop: false,
      slidesPerView: 1,
      centeredSlides: false,
      runCallbacksOnInit: true,
      spaceBetween: 0,
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // And if we need scrollbar
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      on: {
        init: function (sw) {
          var offer = document.querySelector("#numberSlides");
          offer.innerHTML = sw.activeIndex + 1 + " / " + sw.slides.length;
        },
        slideChange: function (sw) {
          var offer = document.querySelector("#numberSlides");
          offer.innerHTML = sw.activeIndex + 1 + " / " + sw.slides.length;
        },
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
          centeredSlides: true,
        },
        1280: {
          slidesPerView: 2,
          spaceBetween: 0,
          centeredSlides: true,
        },
      },
    });
  },
  methods: {
    goDemo: function (e) {
      this.demoSection = true;

      this.demoUrl = e;

      console.log("clicked");
      console.log(this.demoSection);
      // console.log(this.demoSection, this.demoUrl);
    },
    closeDemo: function () {
      this.demoSection = false;
      this.mode = "mode-desktop";
      this.demoUrl = null;
      // console.log(this.demoSection, this.demoUrl);
    },
    selectMode: function (e) {
      this.mode = e;
      // document.getElementById('demo-frame').contentWindow.location.reload()
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./themesDetail.scss" lang="scss" scoped>
</style>
