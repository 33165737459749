<template  v-if="lang">
  <header id="header" :data-dark="
    typeof IsDark !== 'undefined' && IsDark == true ? 'dark' : 'light'
  ">
    <div class="testClass">
      {{ __(general).form.campaign }}
   </div>
    <div class="attention-bar" v-if="__(title).attentionText">
      <div class="container">
        <div class="attention-text" v-html="__(title).attentionText"></div>
      </div>
    </div>
     <div class="container">
      <div class="d-flex">
        <div class="h-area first-side">
          <div class="logo">
            <router-link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323.19 109.18">
                <g id="katman_2" data-name="katman 2">
                  <g id="katman_1-2" data-name="katman 1">
                    <path
                      d="M323.19,109.06c-.76,0-1.53.12-2.29.12H216.45c-2.64,0-2.64,0-2.64-2.55V.08H320.7c.83,0,1.66,0,2.49-.08ZM312.85,98.79V96.62q0-42,0-84c0-1.79-.41-2.37-2.3-2.37q-42.15.09-84.31.05c-.68,0-1.35.06-2,.09V98.79Z" />
                    <path
                      d="M123.85,89.07V20.85a13,13,0,0,1,1.47-.17c9.07,0,18.14-.12,27.2.09,5.56.14,10.7,1.71,14.44,6.28,7,8.55,4.84,20.48-4.72,25.85-.44.24-.87.51-1.42.84.27.21.43.44.63.49,7.7,2.06,10.49,9.38,10.5,15.58,0,9.85-6,17.24-15.38,19.09a17.53,17.53,0,0,1-3.4.27c-9.28,0-18.55,0-27.83,0C124.91,89.19,124.48,89.12,123.85,89.07Zm10.36-9.64a7.54,7.54,0,0,0,1,.14c5.68-.08,11.36-.12,17-.26,6.12-.14,9.46-5.6,9.39-10.32-.09-5.73-3.77-9.79-9.62-10.06-5.61-.26-11.24-.15-16.86-.19a5,5,0,0,0-1,.17Zm0-49.26V49.59c4.61,0,9.06,0,13.52,0a31.69,31.69,0,0,0,5.51-.47c4.27-.81,7.41-5.06,7.33-9.68s-3.27-8.91-7.58-9.16C146.82,29.9,140.58,30.17,134.25,30.17Z" />
                    <path
                      d="M108.52,70.37c-.33,5.08-3.13,9.21-6.71,12.66A24.27,24.27,0,0,1,77,88.43c-11.5-4.05-16.21-11.37-16.78-20.14a164.26,164.26,0,0,1,.27-28.83,19.72,19.72,0,0,1,5-10.94A25.33,25.33,0,0,1,96,22.69c7.56,3.8,12.36,10,12.76,18.83.21,4.45.37,8.94.37,13.4C109.1,59.91,108.85,65.22,108.52,70.37Zm-10-25.73c-.29-9.27-6.84-14-13.24-14.31C78.33,30,71.32,35.1,70.94,41.85c-.43,7.61-.63,15.27-.36,22.88.34,9,4.87,13.88,12.92,14.73A13.45,13.45,0,0,0,98.18,67.52C98.57,63.35,98.61,48.07,98.5,44.64Z" />
                    <path
                      d="M53.39,89.19c-4,0-7.66.07-11.29-.07-.59,0-1.34-.82-1.7-1.44Q32,72.79,23.66,57.83c-.37-.66-.77-1.31-1.23-2.1a15,15,0,0,0-1.19,1c-3.32,3.46-6.68,6.89-9.9,10.43A4.22,4.22,0,0,0,10.23,70c-.11,5.61-.06,11.22-.06,16.83V89H0V20.67c2.84,0,5.57,0,8.31,0,.55,0,1.11.08,1.85.13V55.63l.39.18c.43-.5.87-1,1.28-1.49Q24.82,38.18,37.77,22a3.64,3.64,0,0,1,3-1.37c3.25.09,6.51,0,10.24,0-.63.83-1,1.4-1.48,1.95C43.1,30.48,36.7,38.35,30.23,46.16A2.3,2.3,0,0,0,30,49.3C37.43,61.85,44.8,74.45,52.2,87,52.54,87.63,52.86,88.24,53.39,89.19Z" />
                    <rect x="186.76" y="40.74" width="10.05" height="48.39" />
                    <rect x="186.7" y="20.81" width="10.17" height="10.04" />
                    <path
                      d="M280.44,27.16l-5.66,8.36a32.69,32.69,0,0,0-16.17-5.12c-4.31-.12-8.26.66-11,4.54a8.33,8.33,0,0,0,2.51,12.13c3.15,2,6.78,2.07,10.27,2.75,3.8.74,7.78,1.2,11.28,2.7,8.76,3.72,12.85,13.94,9.86,23.88-2.11,7-7.09,10.79-13.87,12.28-11.4,2.51-22.19.84-32.09-5.55-1.17-.75-2.24-1.65-3.53-2.61l6.73-7.7a31.9,31.9,0,0,0,14.23,6.25c4.39.67,8.79.76,13.05-.76a8.86,8.86,0,0,0,6.13-9.12,8.31,8.31,0,0,0-6.57-7.95c-3.77-.9-7.66-1.31-11.5-2-6.45-1.08-12-3.52-15.73-9.36-5.6-8.8-3-25.16,11.72-28.79,10.39-2.56,20.11-.37,29.16,5.14A12.71,12.71,0,0,1,280.44,27.16Z" />
                    <rect x="292.83" y="40.67" width="9.51" height="48.4" />
                    <path
                      d="M302.33,30.47c-2.95,0-5.75,0-8.55,0a1.45,1.45,0,0,1-1.06-.94c-.08-2.85,0-5.7,0-8.69h9.65Z" />
                  </g>
                </g>
              </svg>
              <!-- <img :src="__(general).logoDark" :alt="__(general).siteTitle" /> -->
            </router-link>
          </div>
          <div class="navigation" :class="mobileMenuOpen ? 'menu-opened' : 'menu-closed'">
            <div class="mobile-menu-header">
              <div class="logo">
                <router-link to="/">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323.19 109.18">
                    <g id="katman_2" data-name="katman 2">
                      <g id="katman_1-2" data-name="katman 1">
                        <path
                          d="M323.19,109.06c-.76,0-1.53.12-2.29.12H216.45c-2.64,0-2.64,0-2.64-2.55V.08H320.7c.83,0,1.66,0,2.49-.08ZM312.85,98.79V96.62q0-42,0-84c0-1.79-.41-2.37-2.3-2.37q-42.15.09-84.31.05c-.68,0-1.35.06-2,.09V98.79Z" />
                        <path
                          d="M123.85,89.07V20.85a13,13,0,0,1,1.47-.17c9.07,0,18.14-.12,27.2.09,5.56.14,10.7,1.71,14.44,6.28,7,8.55,4.84,20.48-4.72,25.85-.44.24-.87.51-1.42.84.27.21.43.44.63.49,7.7,2.06,10.49,9.38,10.5,15.58,0,9.85-6,17.24-15.38,19.09a17.53,17.53,0,0,1-3.4.27c-9.28,0-18.55,0-27.83,0C124.91,89.19,124.48,89.12,123.85,89.07Zm10.36-9.64a7.54,7.54,0,0,0,1,.14c5.68-.08,11.36-.12,17-.26,6.12-.14,9.46-5.6,9.39-10.32-.09-5.73-3.77-9.79-9.62-10.06-5.61-.26-11.24-.15-16.86-.19a5,5,0,0,0-1,.17Zm0-49.26V49.59c4.61,0,9.06,0,13.52,0a31.69,31.69,0,0,0,5.51-.47c4.27-.81,7.41-5.06,7.33-9.68s-3.27-8.91-7.58-9.16C146.82,29.9,140.58,30.17,134.25,30.17Z" />
                        <path
                          d="M108.52,70.37c-.33,5.08-3.13,9.21-6.71,12.66A24.27,24.27,0,0,1,77,88.43c-11.5-4.05-16.21-11.37-16.78-20.14a164.26,164.26,0,0,1,.27-28.83,19.72,19.72,0,0,1,5-10.94A25.33,25.33,0,0,1,96,22.69c7.56,3.8,12.36,10,12.76,18.83.21,4.45.37,8.94.37,13.4C109.1,59.91,108.85,65.22,108.52,70.37Zm-10-25.73c-.29-9.27-6.84-14-13.24-14.31C78.33,30,71.32,35.1,70.94,41.85c-.43,7.61-.63,15.27-.36,22.88.34,9,4.87,13.88,12.92,14.73A13.45,13.45,0,0,0,98.18,67.52C98.57,63.35,98.61,48.07,98.5,44.64Z" />
                        <path
                          d="M53.39,89.19c-4,0-7.66.07-11.29-.07-.59,0-1.34-.82-1.7-1.44Q32,72.79,23.66,57.83c-.37-.66-.77-1.31-1.23-2.1a15,15,0,0,0-1.19,1c-3.32,3.46-6.68,6.89-9.9,10.43A4.22,4.22,0,0,0,10.23,70c-.11,5.61-.06,11.22-.06,16.83V89H0V20.67c2.84,0,5.57,0,8.31,0,.55,0,1.11.08,1.85.13V55.63l.39.18c.43-.5.87-1,1.28-1.49Q24.82,38.18,37.77,22a3.64,3.64,0,0,1,3-1.37c3.25.09,6.51,0,10.24,0-.63.83-1,1.4-1.48,1.95C43.1,30.48,36.7,38.35,30.23,46.16A2.3,2.3,0,0,0,30,49.3C37.43,61.85,44.8,74.45,52.2,87,52.54,87.63,52.86,88.24,53.39,89.19Z" />
                        <rect x="186.76" y="40.74" width="10.05" height="48.39" />
                        <rect x="186.7" y="20.81" width="10.17" height="10.04" />
                        <path
                          d="M280.44,27.16l-5.66,8.36a32.69,32.69,0,0,0-16.17-5.12c-4.31-.12-8.26.66-11,4.54a8.33,8.33,0,0,0,2.51,12.13c3.15,2,6.78,2.07,10.27,2.75,3.8.74,7.78,1.2,11.28,2.7,8.76,3.72,12.85,13.94,9.86,23.88-2.11,7-7.09,10.79-13.87,12.28-11.4,2.51-22.19.84-32.09-5.55-1.17-.75-2.24-1.65-3.53-2.61l6.73-7.7a31.9,31.9,0,0,0,14.23,6.25c4.39.67,8.79.76,13.05-.76a8.86,8.86,0,0,0,6.13-9.12,8.31,8.31,0,0,0-6.57-7.95c-3.77-.9-7.66-1.31-11.5-2-6.45-1.08-12-3.52-15.73-9.36-5.6-8.8-3-25.16,11.72-28.79,10.39-2.56,20.11-.37,29.16,5.14A12.71,12.71,0,0,1,280.44,27.16Z" />
                        <rect x="292.83" y="40.67" width="9.51" height="48.4" />
                        <path
                          d="M302.33,30.47c-2.95,0-5.75,0-8.55,0a1.45,1.45,0,0,1-1.06-.94c-.08-2.85,0-5.7,0-8.69h9.65Z" />
                      </g>
                    </g>
                  </svg>
                  <!-- <img :src="__(general).logoDark" :alt="__(general).siteTitle" /> -->
                </router-link>
              </div>
              <div class="menu-closer" @click="menuOpener">
                <svg class="shape">
                  <use xlink:href="../../assets/images/icon/icon-sprite.svg#right-arrow" />
                </svg>
              </div>
            </div>
            <ul class="site-navigation-menu">
              <li v-for="navigationItem in __(title).routeLinks" :key="navigationItem.id">
                <template v-if="!navigationItem.children">
                  <router-link :to="navigationItem.path" @click.native="menuOpener">{{ navigationItem.pagename }}
                  </router-link>
                </template>
                <template v-else>
                  <span>{{ navigationItem.pagename }}</span>
                </template>

                <template v-if="navigationItem.children">
                  <div class="drop-menu">
                    <ul>
                      <li v-for="(childItem, index) in navigationItem.children" :key="index">
                        <router-link :to="childItem.path" @click.native="menuOpener">{{ childItem.pagename }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </template>
              </li>
            </ul>

            <ul class="account-menu">
              <li class="login" @click="menuOpener">
                <a href="https://admin.kobisi.com/dashboard/login?ret=/dashboard" target="_blank">{{
                __(title).loginButton
                }}</a>
              </li>
              <li class="register" @click="menuOpener">
                <router-link to="/lp-kobisi-eticaret-paketleri">
                  <button type="button" class="bttn primary radiused">{{ __(title).tryButton }}</button>
                </router-link>
              </li>
            </ul>
            <div class="mobile-menu-footer">
              <ul class="social-menu">
                <li @click="menuOpener" v-for="socialItem in __(general).socialMediaLinks.links" :key="socialItem.name">
                  <a :href="'//' + socialItem.route" target="_blank">
                    <svg class="shape">
                      <use :xlink:href="`${spriteUrl}#` + socialItem.id" />
                    </svg>
                    <!-- {{ socialItem.pagename }} -->
                  </a>
                </li>
              </ul>
              <div class="lang-select">
                <div class="selected-lang" tabindex="1">
                  <svg class="shape">
                    <use :xlink:href="`${spriteUrl}#language`" />
                  </svg>
                  <span :v-model="'lang'">{{ __("lang") }}</span>
                </div>
                <ul>
                  <li @click="changeLang('tr'), addClass(t)" :class="{ active: selectedItem == t }">
                    <span>Türkçe</span>
                  </li>
                  <li @click="changeLang('en'), addClass(t)" :class="{ active: selectedItem == t }">
                    <span>English</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="h-area second-side">
          <ul>
            <li class="login">
              <a href="https://admin.kobisi.com/dashboard/login?ret=/dashboard" target="_blank">{{ __(title).loginButton
              }}</a>
            </li>
            <li class="register">
              <a class="bttn primary radiused"><router-link   to="/lp-kobisi-eticaret-paketleri">{{ __(title).tryButton }}</router-link></a>
            </li>
            <!-- <li class="lang-select">
              <div class="selected-lang">
                <svg class="shape">
                  <use :xlink:href="`${spriteUrl}#language`" />
                </svg>
                <span :v-model="'lang'">{{ __("lang") }}</span>
              </div>
              <ul>
                <li
                  @click="changeLang('tr'), addClass(t)"
                  :class="{ active: selectedItem == t }"
                >
                  <span>Türkçe</span>
                </li>
                <li
                  @click="changeLang('en'), addClass(t)"
                  :class="{ active: selectedItem == t }"
                >
                  <span>English</span>
                </li>
              </ul>
            </li> -->
            <li class="mobile-menu" @click="menuOpener">
              <svg class="shape">
                <use xlink:href="../../assets/images/icon/icon-sprite.svg#menu" />
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import tr from "@/lang/tr.js";
import en from "@/lang/en.js";
const iconPath = require("@/assets/images/icon/icon-sprite.svg");
export default {
  mixins: [tr, en],
  name: "headerComponent",
  data() {
    return {
      spriteUrl: iconPath,
      selectedItem: 0,
      t: null,
      mobileMenuOpen: false,
      isDark: null,
    };
  },
  props: {
    lang: String,
    title: String,
    general: String,
    IsDark: Boolean, //sayfa datası içindeki dark değerine göre header light-dark olarak değiştiriyor. Data app.vue da ayarlanıyor.
  },
  beforeUpdate() {

  },
  mounted() { },
  updated() {

  },
  methods: {
    changeLang(lang) {
      this.$emit("changelang", lang);
      // document.location.reload();
      localStorage.setItem("lang", this.lang == 'tr' ? 'en' : 'tr')
    },
    addClass(t) {
      this.selectedItem = t;
    },
    menuOpener: function () {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./header.scss" lang="scss" scoped>

</style>
